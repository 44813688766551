export function getMessageFromCaughtError(
  error: unknown,
  fallbackMessage: string = 'Unknown error'
): string {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === 'string') {
    return error;
  }

  if (typeof error === 'object' && error !== null && 'message' in error) {
    return String(error.message);
  }

  return fallbackMessage;
}
