import { BusinessVerticalWithDefault } from '@/enums/businessVertical';
import { ProductOptions } from '@/types/productOptions';
import { Carrier } from './carrier';
import { Company } from './company';
import { UploadedFile } from './file';
import { Location } from './location';

type Rating = 1 | 2 | 3 | 4 | 5;

export type ContractStatus =
  | 'aktiv'
  | 'storniert'
  | 'ruhend'
  | 'ablauf'
  | 'antrag'
  | 'ablehnung'
  | 'review'
  | 'validated';

export interface Insurance {
  insuranceId: string;
  categoryId: string;
  name: string | null;
  description: string | null;
  deductible: number | null;
  risksInsured: string | null;
  mainRiskInsured: string | null;
  allRisksInsured: string[] | null;
  insuredRiskOtherSpecification?: string | null;
  amountInsured: number | null;
  amountInsuredUnlimited: boolean;
  carrierId: string;
  locationId: string;
  createdAt: string;
  updatedAt: string;
  insuranceLabel: string;
  parametersValues: Record<string, any> | null;
  yesNoValues: Record<string, unknown> | null;
  startDate: Date | string | null;
  commission: number | null;
  closingCommission: number | null;
  endDate: Date | null;
  policyNumber: string | null;
  contractStatus: ContractStatus | null;
  contractType: string | null;
  paymentPeriod: string | null;
  contractPaymentNextDate: Date | null;
  initialCommisionReceived: boolean;
  commissionPaidAt: Date | null;
  commissionPaidTo: string | null;
  consultationProtocol: UploadedFile;
  consultationProtocolExist: boolean;
  status: string;
  netPrice: number | null;
  grossPrice: number | null;
  shouldRenewAutomatically: boolean;
  coverNote?:
    | {
        status: 'SUPPORTED';
        unmetDependencies: string[];
      }
    | {
        status: 'NOT_SUPPORTED';
      }
    | {
        status: 'OTHER_NOT_SUPPORTED';
      };
  /**
   * @deprecated most likely this is not used - Validate button is not shown for all insurances
   */
  requestProtection: {
    status: 'SUPPORTED' | 'NOT_SUPPORTED';
  };
}

export interface InsuranceApiResult extends Insurance {
  location: Pick<Location, 'name' | 'companyId'>; //  | 'address_id'
  assessments?: unknown[];
  agreementNumber?: string;
  carrier?: Carrier;
  paymentInfo?: unknown;
  files: UploadedFile[];
  insuranceTemplate: {
    version: number;
    attributes: { code: string }[];
  } | null;
}

export interface InsuranceProduct {
  insuranceProductId: string;
  categoryId: string;
  businessVertical: BusinessVerticalWithDefault;
  name: string;
  description: string;
  deductible: number;
  risksInsured: string;
  carrierId: string;
  createdAt: Date;
  updatedAt: Date;
  commission: number;
  userId: string | null;
  parametersValues: {
    en: Record<string, string>;
    de: Record<string, string>;
  };
  yesNoValues: Record<string, 0 | 1>;
  highlightedAttributes: Record<string, boolean>;
  contractCoverageRating: Rating | null;
  discountsOptions: number[] | null;
  deductiblesOptions: number[] | null;
  insuranceSumsOptions: number[] | null;
  agreementNumber: string | null;
  disabledFields?: {
    lossOfEarnings?: boolean;
    parameterValues?: Record<string, boolean>;
  };
  options?: ProductOptions;
  isArchived: boolean;
  multiRiskAllowed: boolean;
}

export interface InsuranceProductWithCarrier extends InsuranceProduct {
  carrier: Carrier;
  insuranceProductFiles: UploadedFile[];
}

export interface LocationHomeInsuranceEntry {
  categoryId: Insurance['categoryId'];
  name: Insurance['name'];
  carrierName: string;
  logoUrl: string;
  insuranceId: Insurance['insuranceId'];
  insuranceLabel: Insurance['insuranceLabel'];
  consultationProtocol?: UploadedFile;
  contractStatus: string | null;
  contractType: string | null;
  policyNumber: string | null;
  risksInsured: string | null;
  mainRiskInsured: string | null;
  allRisksInsured: string[] | null;
  insuredRiskOtherSpecification?: string | null;
  createdAt: string;
  sourceRecommendation?: string;
  carrierId: string;
  commission: number;
  startDate: Date | null;
  endDate: Date | null;
  paymentPeriod: string;
  contractPaymentNextDate: Date | null;
  netPrice: number;
  grossPrice: number;
  files: UploadedFile[];
  purchased: boolean;
  sourceRecommendationId?: string;
  yearlyCost: number;
}

export interface Expiring {
  insuranceName: Insurance['name'];
  insuranceId: Insurance['insuranceId'];
  endDate: string;
  contractType: Insurance['contractType'];
  categoryId: Insurance['categoryId'];
  policyNumber: Insurance['policyNumber'];
  status: Insurance['status'];
  companyId: Company['companyId'];
  companyName: Company['name'];
  isTest: Company['isTest'];
  locationId: Location['locationId'];
  locationName: Location['name'];
  carrierId: Carrier['carrierId'];
  carrierName: Carrier['name'];
  carrierLogoUrl: Carrier['logoUrl'];
}

export function isInsuranceValidated(insurance: Insurance) {
  return insurance.contractStatus === 'validated';
}

export function isProtectionRequested(insurance: Insurance) {
  return insurance.contractStatus === 'antrag';
}

export function canShowRequestProtection(insurance?: Insurance) {
  return insurance?.contractStatus === 'review';
}

export function findCoverNote(
  insurance: InsuranceApiResult
): UploadedFile | undefined {
  return insurance.files.find(({ fileType }) => fileType === 'cover_note');
}
