import {
  PriceResult,
  RecommendationProduct,
  RecommendationProductPriceFetchResult
} from '@/types/recommendation';
import {
  useRecalculatePrice,
  useUpdateRecommendationProduct
} from '@/services';
import { useState } from 'react';
import { InsuranceProduct } from '@/types/insurance';
import { makeFormattedOptions } from '@/pages/RecommendationPage/helpers';

interface ProductActions {
  fetchRecommendationProductPrice: (
    productIndex: number
  ) => Promise<RecommendationProductPriceFetchResult>;
  updateRecommendationProduct: (
    productIndex: number,
    payload: Record<string, unknown>
  ) => Promise<RecommendationProductPriceFetchResult>;
  updateAllRecommendationProducts: (
    payload: Record<string, unknown>
  ) => Promise<Array<RecommendationProductPriceFetchResult | undefined>>;
}

/**
 * Provides actions for interacting with recommendation products in the recommendation view
 */
export function useProductsActions(
  recommendationProducts: RecommendationProduct[]
): ProductActions {
  const fetchPriceMutation_0 = useRecalculatePrice(
    recommendationProducts[0]?.recommendationProductId
  );
  const fetchPriceMutation_1 = useRecalculatePrice(
    recommendationProducts[1]?.recommendationProductId
  );
  const fetchPriceMutation_2 = useRecalculatePrice(
    recommendationProducts[2]?.recommendationProductId
  );

  const fetchPrices = [
    fetchPriceMutation_0,
    fetchPriceMutation_1,
    fetchPriceMutation_2
  ];

  const updateRecommendationProduct_0 = useUpdateRecommendationProduct(
    recommendationProducts[0]?.recommendationProductId
  );
  const updateRecommendationProduct_1 = useUpdateRecommendationProduct(
    recommendationProducts[1]?.recommendationProductId
  );
  const updateRecommendationProduct_2 = useUpdateRecommendationProduct(
    recommendationProducts[2]?.recommendationProductId
  );
  const updateRecommendationProducts = [
    updateRecommendationProduct_0,
    updateRecommendationProduct_1,
    updateRecommendationProduct_2
  ];

  return {
    fetchRecommendationProductPrice: (
      productIndex: number
    ): Promise<RecommendationProductPriceFetchResult> =>
      fetchPrices[productIndex]?.mutateAsync(undefined),
    updateRecommendationProduct: (
      productIndex: number,
      payload: Record<string, unknown>
    ): Promise<RecommendationProductPriceFetchResult> =>
      updateRecommendationProducts[productIndex]?.mutateAsync(payload),
    updateAllRecommendationProducts: (
      payload: Record<string, unknown>
    ): Promise<Array<RecommendationProductPriceFetchResult | undefined>> => {
      return Promise.all(
        [0, 1, 2].map((productIndex) => {
          if (recommendationProducts[productIndex]) {
            return updateRecommendationProducts[productIndex]?.mutateAsync(
              payload
            );
          }

          return undefined;
        })
      );
    }
  };
}

type PriceMetadata = Record<number, Omit<PriceResult, 'carrierResponse'>>;

/**
 * Keeps the price metadata for 3 products in recommendation form page
 */
export function usePriceMetadata(
  initial: PriceMetadata = {}
): [
  PriceMetadata,
  (productIndex: number, payload: Omit<PriceResult, 'carrierResponse'>) => void
] {
  const [priceMetadata, setPriceMetadata] = useState(initial);

  return [
    priceMetadata,
    (productIndex: number, payload: Omit<PriceResult, 'carrierResponse'>) => {
      const newPriceMetadata = { ...priceMetadata };
      newPriceMetadata[productIndex] = payload;
      setPriceMetadata(newPriceMetadata as PriceMetadata);
    }
  ];
}

type ChosenProduct = Partial<InsuranceProduct> & {
  formattedDiscountOptions: null | { value: number; label: string }[];
  formattedInsuranceSumsOptions: null | { value: number; label: string }[];
  formattedDeductiblesOptions: null | { value: number; label: string }[];
};

/**
 * Returns InsuranceProduct decorated with options for selects
 */
export function useChosenInsuranceProducts(
  products: Partial<InsuranceProduct>[],
  recommendationProducts: RecommendationProduct[]
): Record<number, ChosenProduct> {
  return recommendationProducts.map(({ insuranceProductId }) => {
    const chosen = products.find(
      (item) => item.insuranceProductId === insuranceProductId
    );

    if (!chosen) {
      throw new Error(`Unable to find insurance product ${insuranceProductId}`);
    }

    return {
      ...chosen,
      formattedDiscountOptions: makeFormattedOptions(
        chosen.discountsOptions || null,
        'percent'
      ),
      formattedInsuranceSumsOptions: makeFormattedOptions(
        chosen.insuranceSumsOptions || null,
        'money'
      ),
      formattedDeductiblesOptions: makeFormattedOptions(
        chosen.deductiblesOptions || null,
        'money'
      )
    };
  });
}

/**
 * Holds state about price metadata modal visibility per product
 */
export function useMetadaModalVisibility(
  initial: Record<number, boolean> = { 0: false, 1: false, 2: false }
): {
  metadataModalVisibility: Record<number, boolean>;
  openMetadataModal: (productIndex: number) => void;
  closeMetadataModal: (productIndex: number) => void;
} {
  const [visibility, setVisibility] = useState(initial);

  return {
    metadataModalVisibility: visibility,
    openMetadataModal: (productIndex: number) => {
      const newVisibility = { ...visibility };
      newVisibility[productIndex] = true;
      setVisibility(newVisibility);
    },
    closeMetadataModal: (productIndex: number) => {
      const newVisibility = { ...visibility };
      newVisibility[productIndex] = false;
      setVisibility(newVisibility);
    }
  };
}
