import React, { useState } from 'react';
import { isNil } from 'ramda';

import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd';
import './styles.scss';

const sumMbSize = (files: { size: number }[]): number => {
  const totalSize = files.reduce((acc, file) => acc + file.size, 0);
  return totalSize / 1024 ** 2;
};

interface FileRecord {
  fileId: string;
  name: string;
  fileName?: string;
  displayName: string;
  category?: string | null;
  visibleToUser: boolean;
  createdAt: Date;
}

interface DandDProps {
  files?: Array<FileRecord>;
  onChange: (
    file: File,
    options: { onSuccess: () => void; onError: () => void }
  ) => void;
  multiple?: boolean;
  accept?: string;
  maxMbSize?: number;
  max?: number;
  maxUploadLimit?: number;
  disabled?: boolean;
}

export const DandD = ({
  files = [],
  onChange,
  multiple,
  accept,
  maxMbSize,
  max,
  maxUploadLimit = 10,
  disabled
}: DandDProps) => {
  const [isUploading, setIsUploading] = useState(false);

  const isDisabled =
    disabled ||
    max === 0 ||
    (!isNil(max) && max - files.length <= 0) ||
    isUploading;

  return (
    <div className="dragger-wrapper">
      <Upload.Dragger
        disabled={isDisabled}
        showUploadList={false}
        multiple={multiple}
        accept={accept}
        customRequest={({ file }) => {
          // Not sure why but `file` here is string | Blob | RcFile
          // https://github.com/react-component/upload states it's file
          const fileToUpload = file as File;
          const fileName = fileToUpload.name;

          if (files.find(({ name }) => fileName === name)) {
            message.error(`${fileName} already uploaded!`);
            return;
          }

          setIsUploading(true);
          onChange(fileToUpload, {
            onSuccess: () => setIsUploading(false),
            onError: () => setIsUploading(false)
          });
        }}
        beforeUpload={(_, item) => {
          if (isDisabled) {
            return false;
          }

          if (maxUploadLimit && item.length > maxUploadLimit) {
            message.error(
              `You cannot upload more than ${maxUploadLimit} files at once`
            );
            return false;
          }

          if (!isNil(maxMbSize) && sumMbSize(item) > maxMbSize) {
            message.error(`Maximum size is ${maxMbSize}MB`);
            return false;
          }

          if (max && item.length > max - files.length) {
            message.error(
              `You cannot select more than ${
                max - files.length
              } files, total maximum is ${max}`
            );
            return false;
          }

          return true;
        }}
      >
        <p className="ant-upload-drag-icon">
          {isUploading ? (
            <LoadingOutlined style={{ fontSize: 32 }} spin />
          ) : (
            <UploadOutlined />
          )}
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        {!isDisabled && !isNil(max) && (
          <p className="ant-upload-hint">
            You can still choose up to {max - files.length} files!
          </p>
        )}
      </Upload.Dragger>
    </div>
  );
};
