import {
  createRecommendationProduct,
  recalculatePrice,
  updateRecommendationProduct,
  updateRecommendationProductByInsuranceProductId
} from '@/api';
import { useInvalidateOnSuccessMutation } from '@/utils';
import {
  RecommendationProductPriceFetchResult,
  RecommendationProductResult
} from '@/types/recommendation';

const keys = {
  recommendationProducts: 'recommendationProducts'
};

export const useCreateRecommendationProduct = (recommendationId: string) =>
  useInvalidateOnSuccessMutation<RecommendationProductResult>(
    [keys.recommendationProducts, recommendationId],
    (insuranceProductId) =>
      createRecommendationProduct({ recommendationId, insuranceProductId })
  );

export const useRecalculatePrice = (recommendationProductId: string) =>
  useInvalidateOnSuccessMutation<RecommendationProductPriceFetchResult>(
    keys.recommendationProducts,
    () => recalculatePrice(recommendationProductId)
  );

export const useUpdateRecommendationProductByInsuranceProduct = (
  recommendationProductId: string
) =>
  useInvalidateOnSuccessMutation<RecommendationProductResult>(
    keys.recommendationProducts,
    (insuranceProductId) =>
      updateRecommendationProductByInsuranceProductId({
        recommendationProductId,
        insuranceProductId
      })
  );

export const useUpdateRecommendationProduct = (id: string) =>
  useInvalidateOnSuccessMutation<
    RecommendationProductPriceFetchResult,
    unknown,
    Record<string, any>, // no good way around this any :)
    unknown
  >(
    keys.recommendationProducts,
    ({
      deductible,
      parametersValues,
      amountInsured,
      amountInsuredUnlimited,
      grossPrice,
      netPrice,
      yesNoValues,
      highlightFields,
      highlightText,
      highlightedAttributes,
      paymentPeriod,
      startDate,
      commission,
      offerTag,
      discountValue,
      risksInsured,
      lossOfEarnings,
      options
    }) => {
      return updateRecommendationProduct({
        id,
        deductible,
        parametersValues,
        amountInsured,
        amountInsuredUnlimited,
        grossPrice,
        netPrice,
        yesNoValues,
        paymentPeriod,
        highlightFields,
        highlightText,
        highlightedAttributes,
        startDate,
        commission,
        offerTag,
        discountValue,
        risksInsured,
        lossOfEarnings,
        options
      });
    }
  );
