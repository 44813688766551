import { SyncState } from '@/components/Uploader/types';
import { Badge, BadgeProps, Popover } from 'antd';
import React from 'react';
import './sync-status.scss';

const FileStatusBadge = (props: BadgeProps) => {
  return (
    <div className="file-status-badge">
      <Badge {...props} />
    </div>
  );
};
type SyncStatusProps = {
  state: SyncState;
  message: string;
};
export const SyncStatus = ({ state, message }: SyncStatusProps) => {
  const badgeStatusMap = {
    IN_QONTO: 'success',
    PROGRESS: 'processing',
    ERROR: 'error',
    NOT_IN_QONTO: 'default'
  } as const;

  const tooltipMessageMap: Partial<Record<SyncState, string>> = {
    IN_QONTO: 'File was successfully uploaded to Qonto',
    NOT_IN_QONTO: 'File is not uploaded to Qonto',
    ERROR: 'Unknown error'
  };

  const badgeTextMap: Partial<Record<SyncState, string>> = {
    IN_QONTO: 'Synced',
    NOT_IN_QONTO: 'Not synced',
    PROGRESS: 'Processing',
    ERROR: 'Error'
  };

  const currentStatus = badgeStatusMap[state];
  const statusMessage = message ?? tooltipMessageMap[state];

  if (state === 'ERROR') {
    return (
      <div>
        <Popover
          title="Error when syncing"
          content={
            <div>
              <div>{statusMessage}</div>
              {/*
              will be added when BE is supporting
              <Button>Resync</Button>
               */}
            </div>
          }
        >
          <FileStatusBadge status={currentStatus} text={badgeTextMap[state]} />
        </Popover>
      </div>
    );
  }

  if (currentStatus && statusMessage) {
    return (
      <div>
        <Popover content={statusMessage}>
          <FileStatusBadge status={currentStatus} text={badgeTextMap[state]} />
        </Popover>
      </div>
    );
  }

  return (
    <div>
      {currentStatus ? (
        <FileStatusBadge status={currentStatus} text={badgeTextMap[state]} />
      ) : null}
    </div>
  );
};
