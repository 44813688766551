import React from 'react';
import { UploadedFile } from '@/types/file';
import { Button, Popconfirm } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

interface Props {
  files: UploadedFile[];
  downloadFile: (name: string) => void;
  deleteFile: (fileId: string) => void;
}

export const FileListingSimple: React.FC<Props> = ({
  files,
  downloadFile,
  deleteFile
}) => {
  return (
    <div>
      {files.map((item) => (
        <div
          key={item.fileId}
          title={item.fileName || item.name}
          className="filename-wrapper"
        >
          <Button type="link" onClick={() => downloadFile(item.name)}>
            <span className="filename-link">{item.fileName || item.name}</span>
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this file?"
            overlayStyle={{ zIndex: 1000000 }}
            onConfirm={() => deleteFile(item.fileId)}
          >
            <CloseOutlined />
          </Popconfirm>
        </div>
      ))}
    </div>
  );
};
