import React, { useState } from 'react';
import { isNil } from 'ramda';
import {
  Button,
  Dropdown,
  Input,
  message,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tooltip
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { UploadedFile } from '@/types/file';
import { format } from 'date-fns';
import { MenuOutlined } from '@ant-design/icons';
import { getMessageFromCaughtError } from '@/utils/error';
import { UploaderFile } from '@/components/Uploader/types';
import { SyncStatus } from '@/components/Uploader/SyncStatus';

interface Props {
  files: UploaderFile[];
  isDisabled: boolean;
  downloadFile: (name: string) => void;
  updateFile: (
    fileId: string,
    data: {
      displayName?: string;
      category?: string;
      visibleToUser?: boolean;
    }
  ) => Promise<void>;
  deleteFile: (fileId: string) => void;
  availableCategories: { value: string | null; label: string }[];
}

export const FileListingExtended = ({
  files,
  isDisabled,
  downloadFile,
  updateFile,
  deleteFile,
  availableCategories
}: Props) => {
  const [editId, setEditId] = useState<string | null>(null);

  const hasStatusColumn = files.some((file) => !isNil(file.syncStatus));

  let columns: ColumnsType<UploadedFile> = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (displayName: string, item) =>
        item.fileId === editId ? (
          <Input
            disabled={isDisabled}
            defaultValue={displayName}
            onBlur={async (e) => {
              try {
                await updateFile(editId, { displayName: e.target.value });
                setEditId(null);
              } catch (error) {
                message.error(
                  `Failed to update file name: ${getMessageFromCaughtError(error)}`
                );
                setEditId(null);
              }
            }}
          />
        ) : (
          <Tooltip title={displayName} zIndex={1000000}>
            <Button type="link" onClick={() => downloadFile(item.name)}>
              <span className="filename-link">{displayName}</span>
            </Button>
          </Tooltip>
        )
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category: string | null = null, { fileId }) => (
        <Select
          className="category-select"
          disabled={isDisabled}
          defaultValue={category}
          onChange={async (newCategory) => {
            await updateFile(fileId, { category: newCategory });
          }}
        >
          {availableCategories.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      title: 'Visible',
      dataIndex: 'visibleToUser',
      key: 'visibleToUser',
      render: (visible, { fileId }) => (
        <Switch
          disabled={isDisabled}
          defaultChecked={visible}
          onChange={async (visibleToUser) => {
            await updateFile(fileId, { visibleToUser });
          }}
        />
      )
    },
    {
      title: 'Added Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        <span>{format(new Date(createdAt), 'dd/MM/yyyy')}</span>
      )
    },
    {
      title: 'Sync status',
      dataIndex: 'syncStatus',
      key: 'syncStatus',
      render: (syncStatus) => (
        <SyncStatus state={syncStatus.state} message={syncStatus.message} />
      )
    },
    {
      title: 'Actions',
      dataIndex: '',
      key: 'actions',
      render: (__, item) => (
        <div className="file-action-dropdown">
          <Dropdown
            className="file-action-dropdown"
            disabled={isDisabled}
            menu={{
              items: [
                {
                  key: 'edit',
                  label: 'Edit',
                  onClick: () => setEditId(item.fileId)
                },
                {
                  danger: true,
                  key: 'delete',
                  label: (
                    <Popconfirm
                      title="Are you sure to delete this file?"
                      onConfirm={() => deleteFile(item.fileId)}
                    >
                      <div className="delete-text">Delete</div>
                    </Popconfirm>
                  )
                }
              ]
            }}
          >
            <MenuOutlined className="actions-icon" />
          </Dropdown>
        </div>
      )
    }
  ];

  if (!hasStatusColumn) {
    columns = columns.filter((column) => column.key !== 'syncStatus');
  }

  return (
    <Table
      dataSource={files}
      columns={columns}
      className="file-table"
      rowKey="fileId"
    />
  );
};
